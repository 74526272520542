/* src/index.css */

@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

@media screen and (max-width: 679px) {

    .job-selected .jobList {
        display: none;
    }

    .job-selected .closeButton {
        display: block !important;
    }
}

.editorContentWrapper ul {
    list-style-type: disc;
    list-style-position: inside;
}